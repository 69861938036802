import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ILoginForm } from '../interfaces/auth.interface';
import { environment } from '../../../environments/environment';
import { AuthStatus } from '../interfaces/authStatus.enum';
import { UsersService } from '../../home/users/services/users.service';
import { IUsers } from '../../home/users/interfaces/users.interface';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private http = inject(HttpClient);
  private userService = inject(UsersService);
  private apiBase = environment.baseUrl;
  private centralBase = environment.centralUrl;
  public authStatus = computed(() => this._authStatus());
  private _authStatus = signal<AuthStatus>(AuthStatus.CHECKING)
  private _currentUser = signal<IUsers | null>(null)
  public currentUser = computed(() => this._currentUser());

  checkAuthStatus() {
    const token = localStorage.getItem('jwt-trueke');
    if (token) {
      this._authStatus.set(AuthStatus.AUTHENTICATED);
      const decoded = JSON.parse(atob(token.split('.')[1]));
      //TODO: COMPARAR FECHA DE EXPIRACION DEL TOKEN
      const mockUser: IUsers = {
        _id: decoded.sub,
        fullName: "",
        email: "",
        rol: decoded.rol,
      }
      this._currentUser.set(mockUser)
      this.userService.getUserProfile(decoded.sub)
        .subscribe(r => {
          this._currentUser.set(r)
        })
    } else {
      this._authStatus.set(AuthStatus.UNAUTHENTICATED);
    }
  }

  setToken(token: string) {
    localStorage.setItem('jwt-trueke', token);
    this._authStatus.set(AuthStatus.AUTHENTICATED);
  }

  refreshToken() {
    const token = localStorage.getItem('jwt-trueke');
    if (!token) {
      this.authLogout();
      return;
    }
    const decoded = JSON.parse(atob(token.split('.')[1]));
    if (decoded.exp < Date.now() / 1000) {
      this.authLogout();
    }
    const expThreshold = decoded.exp - Date.now()/1000;
    
    if (expThreshold < 360) {
      this.http.get(`${this.apiBase}/api/v1/auth/refresh`).subscribe({
        next: (response: any) => {
          this.setToken(response.access_token);
        },
      });
    }
  }

  authLogin(data: ILoginForm) {
    const url = `${this.apiBase}/api/v1/auth/sign-in`;
    return this.http.post(url, data)
      .pipe(
        tap((response: any) => {
          this.setToken(response.access_token)
          this.checkAuthStatus();
        })
      )
  }

  authRegister(data: FormData) {
    const url = `${this.apiBase}/api/v1/auth/sign-up`;
    return this.http.post(url, data)
      .pipe(
        tap((response: any) => {
          this.setToken(response.access_token)
          this.checkAuthStatus();
        })
      )
  }

  forgotPassword(data: any) {
    const url = `${this.apiBase}/api/v1/auth/forgot-password`;
    return this.http.post(url, data)
  }

  authLogout() {
    localStorage.removeItem('jwt-trueke');
    this._authStatus.set(AuthStatus.UNAUTHENTICATED);
    this._currentUser.set(null);
  }
}
